import * as React from "react"
import { graphql } from 'gatsby'
// import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"
// import { GatsbyImage } from "gatsby-plugin-image"

import Seo from "../components/seo"
import AnimationWrapper from "../components/animationWrapper"
import ContactSection from "../components/contactSection"
import Container from "../components/container"
import Header from "../components/header"
import IconSection from "../components/iconSection"
import InnerSection from "../components/innerSection"
import LightboxImageSection from "../components/lightboxImageSection"
import TextSection from "../components/textSection"
import Timeline from "../components/timeline"
import Layout from "../components/layout"

import "./projektowanie.css"

const ProjektowaniePage = ({data, location}) => {

  // set meta description values
  const metaDescription = data.page.frontmatter.meta_description || `Czamaninek ➤ ${data.page.frontmatter.title} ✔️ Ekologiczne materiały budowlane ✔️ Polski producent ⭐ Sprawdź!`;

  return (
    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} description={metaDescription} />

      <AnimationWrapper>
        <Header data={data.page.frontmatter.header} slug={data.page.frontmatter.slug} />
      </AnimationWrapper>

      <Container>
        <TextSection className="description-medium" data={data.page.frontmatter.text_sections[0]} />
      </Container>

      <Timeline data={data.timelineFullService} name="projektowanie" />

      <Container>
        <TextSection className="description-medium align-left" data={data.page.frontmatter.text_sections[1]} />
      </Container>

      <section id="projektowanie-video" class="video-section page-section">
        <AnimationWrapper className="youtube-wrapper">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/sjEaITWCBt4?autoplay=1&mute=1&loop=1&playlist=sjEaITWCBt4&controls=0&showinfo=0"
            title="Projektowanie Czamaninek"
            frameborder="0"
            lazy={true}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen>
          </iframe>
        </AnimationWrapper>
      </section>

      <InnerSection className="projektowanie-inner section-description">

        <TextSection className="description-medium align-left" data={data.page.frontmatter.text_sections[2]} />

        <div className="image-container">
          <div className="content-container">
            <LightboxImageSection image={data.klad1} />
          </div>
        </div>

      </InnerSection>

      <LightboxImageSection image={data.klad2} />

      <LightboxImageSection image={data.strop} />

      <Container>
        <TextSection className="model-3d-desc description-medium" data={data.page.frontmatter.text_sections[3]} />
      </Container>

      <LightboxImageSection image={data.rzut1} />

      <section id="model-3d-icons" className="page-section">
        <Container>
          <AnimationWrapper animationType="fadeIn">
            <IconSection data={data.page.frontmatter} location="projektowanie" />
          </AnimationWrapper>
        </Container>
      </section>

      <LightboxImageSection image={data.rzut2} />

      <section id="contact-data" className="page-section">
        <ContactSection data={data.contactSection.frontmatter.contact_section} />
      </section>

    </Layout>
  )
}

export const query = graphql`
  query{
    page: mdx(frontmatter: { slug: { eq: "projektowanie" }, type: { eq: "page" } }) {
      frontmatter{
        title
        meta_description
        slug
        header{
          title
          breadcrumbs {
            title
            url
          }
          overlay
          image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
        text_sections{
          title
          title_visible
          subtitle
          icon{
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
          text
        }
        icons_section_title
        icons{
          title
          text
          image{
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
      }
    }
    timelineFullService: mdx(internal: {contentFilePath: {regex: "/(timelines/projektowanie)/"}}) {
      frontmatter {
        title
        type
        timestamps{
          title
          color
          background_color
          desc
          icons{
            title
            image{
              childImageSharp {
                gatsbyImageData(
                  transformOptions: {fit: COVER},
                  webpOptions: {quality: 95}
                )
              }
            }
            text
          }
        }
      }
      body
    }
    klad1: file(base: {eq: "klad-1.jpg"}) {
      id
      publicURL
      childImageSharp {
        gatsbyImageData
      }
    }
    klad2: file(base: {eq: "klad-2.jpg"}) {
      id
      publicURL
      childImageSharp {
        gatsbyImageData
      }
    }
    strop: file(base: {eq: "rzut-stropu.jpg"}) {
      id
      publicURL
      childImageSharp {
        gatsbyImageData
      }
    }
    rzut1: file(base: {eq: "rzut-1.png"}, relativeDirectory: {eq: "projektowanie"}) {
      id
      publicURL
      childImageSharp {
        gatsbyImageData
      }
    }
    rzut2: file(base: {eq: "rzut-2.png"}, relativeDirectory: {eq: "projektowanie"}) {
      id
      publicURL
      childImageSharp {
        gatsbyImageData
      }
    }
    contactSection: mdx(
      frontmatter: {slug: {eq: "contact-section"}, type: {eq: "global"}}
    ){
      frontmatter{
        contact_section{
          title
          description
          type
          fields
          submit_button_style
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
          image_object_fit
          image_object_position
        }
      }
    }
  }
`

export default ProjektowaniePage
