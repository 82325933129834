import * as React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'
import Title from "./title"
// import PropTypes from "prop-types"

import AnimationWrapper from "./animationWrapper"
import Container from "./container"

import "./timeline.css"

const Timeline = ( { data, name } ) => {

    return (
      <section className={`${name}-timeline timeline-section page-section`}>
        <Container>
          <AnimationWrapper className="timeline-wrapper">

            {data.frontmatter.title &&
              <Title className="timeline-title" title={data.frontmatter.title} />
            }

            <div className="timestamps-container">
              {data.frontmatter.timestamps.map((timestamp) => (
                <div className="timestamp">

                  <div className="timestamp-title-wrapper">

                    <div className="timestamp-title-before" style={{background: `${timestamp.background_color}`}}></div>

                    <h3 className="timestamp-title" style={{background: `${timestamp.background_color}`}}>
                      {timestamp.title}
                    </h3>

                    <div className="timestamp-title-after" style={{borderLeft: `30px solid ${timestamp.background_color}`}}></div>

                  </div>

                  {timestamp.desc &&
                    <p className="timestamp-desc">
                      {timestamp.desc}
                   </p>
                  }

                  <div className="timestamp-icons" style={{background: `${timestamp.background_color}`, color: `${timestamp.color}`}}>

                    <div className="timestamp-icons-before" style={{border: `3px solid ${timestamp.background_color}`}}></div>

                    {timestamp.icons.map((icon) => (

                      <div className="icon-wrapper">
                        <GatsbyImage
                          image={icon.image.childImageSharp.gatsbyImageData}
                          alt={icon.title}
                        />
                        <div className="icon-txt-wrapper">
                          <h4 className="icon-title">
                            {icon.title}
                          </h4>
                          {icon.text &&
                            <p className="icon-desc">
                              {icon.text}
                            </p>
                          }
                        </div>
                      </div>

                    ))}
                  </div>

                </div>
              ))}
            </div>

          </AnimationWrapper>
        </Container>
      </section>
    )
}

export default Timeline