import * as React from "react"
import PropTypes from "prop-types"

import Button from "./button"
import Title from "./title"

import "./textSection.css"

const TextSection = ({ className, data, headingTag }) => {

  return (
    <div className={`page-section text-section ${className} ${data.align} ${data.button_text ? 'has-btn' : ''}  ${data.text ? '' : 'no-desc'} `}>
      {data.title_visible &&

        <Title
          className={`${data.type} ${data.subtitle ? "has-subtitle" : ""}` }
          title={data.title}
          subtitle={data.subtitle}
          headingTag={headingTag}
          icon={data.icon}
        />
      }
      {data.text &&
        <p className="section-description" dangerouslySetInnerHTML={{ __html: data.text }}/>
      }
      {/* {data.image &&
        <div>
          <GatsbyImage
            image={data.image.childImageSharp.gatsbyImageData}
            alt={data.image_alt}
          />
        </div>
      } */}
      {data.button_text &&
        <Button btnStyle={data.button_style} btnLink={data.button_url} btnText={data.button_text}/>
      }
    </div>
  )
}

TextSection.defaultProps = {
  headingTag: `h2`,
}

TextSection.propTypes = {
  headingTag: PropTypes.string,
}

export default TextSection