import * as React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"

const LightboxImage = ( { image } ) => {

    return (
      <SimpleReactLightbox>
        <SRLWrapper>
          <p key={image.id}>
            <a href={image.publicURL}>
              <GatsbyImage image={image.childImageSharp.gatsbyImageData} />
            </a>
          </p>
        </SRLWrapper>
      </SimpleReactLightbox>
    )
  }

export default LightboxImage