import * as React from "react"

import AnimationWrapper from "./animationWrapper"
import Container from "./container"
import LightboxImage from "./lightboxImage"

const LightboxImageSection = ( {className, image, animationType, animationDuration, threshold, triggerOnce} ) => {

    return (
      <Container className={className}>
        <AnimationWrapper animationType={animationType} animationDuration={animationDuration} threshold={threshold} triggerOnce={triggerOnce}>
          <LightboxImage image={image} />
        </AnimationWrapper>
      </Container>
    )
  }

export default LightboxImageSection